import { defineStore } from 'pinia'

export class SnackebarOptions {
  /** 提示框顏色 */
  color = 'success'

  /** 提示內容 */
  content!: string

  /** 提示位置 */
  location: 'top' | 'bottom' | 'start' | 'end' | 'left' | 'right' | 'center' | 'center center' = 'top'

  position?: 'fixed' | 'absolute' | 'static' | 'relative' | 'sticky' | undefined

  /** 多時間消失 between 4000 to 10000 */
  timeout?: string | number = '4000'

  /** 提示框樣式 */
  variant?: 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain'

  /** 寬度 */
  width?: string | number

  /** z index */
  zIndex?: string | number

  constructor(...args: any) {
    Object.assign(this, ...args)
  }
}

const snackebarStore = defineStore('snackebarStore', {
  state: () => ({
    snackebarOptions: new SnackebarOptions(),
  }),
  getters: {},
  actions: {
    showMessage(options: SnackebarOptions) {
      this.snackebarOptions = options
    },
    success(content: string) {
      this.snackebarOptions.content = content
      this.snackebarOptions.color = 'success'
    },
    warning(content: string) {
      this.snackebarOptions.content = content
      this.snackebarOptions.color = 'warning'
    },
    info(content: string) {
      this.snackebarOptions.content = content
      this.snackebarOptions.color = 'info'
    },
    error(content: string) {
      this.snackebarOptions.content = content
      this.snackebarOptions.color = 'error'
    },
  },
})

export default snackebarStore
