import { defineStore } from 'pinia'

export class DialogOptions {
  /** 卡片標題 */
  title?: string

  /** 卡片內容 */
  content!: string | undefined | null

  /** 確認按鈕文字 */
  okText = '確定'

  /** 取消按鈕文字 */
  cancelText = '取消'

  /** 寬度 */
  width = 'auto'

  /** 當點擊確定呼叫函式 */
  async okCallback() {
    console.log('dialog confirm okCallback')
  }

  /** 當點擊取消呼叫函式 */
  async cancelCallback() {
    console.log('dialog confirm cancelCallback')
  }

  constructor(...args: any) {
    Object.assign(this, ...args)
  }
}

const dialogStore = defineStore('dialogStore', {
  state: () => ({
    dialogOptions: new DialogOptions(),
  }),
  getters: {},
  actions: {
    confirm(title: string, content: string | undefined | null, okCallback: () => Promise<any>, cancelCallback: () => Promise<any>) {
      this.dialogOptions.title = title
      this.dialogOptions.content = content
      this.dialogOptions.okCallback = okCallback
      this.dialogOptions.cancelCallback = cancelCallback
    },
    info(title: string, content: string | undefined | null) {
      this.dialogOptions.title = title
      this.dialogOptions.content = content
    },
  },
})

export default dialogStore
